import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";

import RouterViews from "@/enums/RouterViews";
import Routes from "@/enums/Routes";

const routes: RouteRecordRaw[] = [
  {
    path: `${Routes.HOME.path}`,
    name: `${Routes.HOME.name}`,
    components: {
      [RouterViews.MAIN_HEADER]: () => import("@COMP/MainHeader.vue"),
      [RouterViews.MAIN_CONTENT]: () => import("@VIEWS/Home.vue"),
      [RouterViews.MAIN_FOOTER]: () => import("@COMP/MainFooter.vue"),
    },
  },
  {
    path: `${Routes.INNER.path}/:id`,
    name: `${Routes.INNER.name}`,
    components: {
      [RouterViews.MAIN_HEADER]: () => import("@COMP/MainHeader.vue"),
      [RouterViews.MAIN_CONTENT]: () => import("@VIEWS/InnerPage.vue"),
      [RouterViews.MAIN_FOOTER]: () => import("@COMP/MainFooter.vue"),
    },
  },
  {
    path: `${Routes.FAMILY_LIFE_EDUCATION.path}/`,
    name: `${Routes.FAMILY_LIFE_EDUCATION.name}`,
    components: {
      [RouterViews.MAIN_HEADER]: () => import("@COMP/MainHeader.vue"),
      [RouterViews.MAIN_CONTENT]: () =>
        import("@VIEWS/FamilyLifeEducation.vue"),
      [RouterViews.MAIN_FOOTER]: () => import("@COMP/MainFooter.vue"),
    },
    children: [
      {
        name: Routes.FAMILY_LIFE_EDUCATION_ITEM.name,
        path: `${Routes.FAMILY_LIFE_EDUCATION_ITEM.path}`,
        component: () => import("@VIEWS/FamilyLifeEducationItem.vue"),
      },
    ],
  },
  {
    path: `${Routes.SERVICES_SUPPORT.path}/`,
    name: `${Routes.SERVICES_SUPPORT.name}`,
    components: {
      [RouterViews.MAIN_HEADER]: () => import("@COMP/MainHeader.vue"),
      [RouterViews.MAIN_CONTENT]: () =>
        import("@VIEWS/ServicesSupport.vue"),
      [RouterViews.MAIN_FOOTER]: () => import("@COMP/MainFooter.vue"),
    },
    children: [
      {
        name: Routes.SERVICES_SUPPORT_ITEM.name,
        path: `${Routes.SERVICES_SUPPORT_ITEM.path}`,
        component: () => import("@VIEWS/ServicesSupportItem.vue"),
      },
    ],
  },
  {
    path: `${Routes.SERVICES.path}/:id`,
    name: `${Routes.SERVICES.name}`,
    components: {
      [RouterViews.MAIN_HEADER]: () => import("@COMP/MainHeader.vue"),
      [RouterViews.MAIN_CONTENT]: () => import("@VIEWS/OfferingPage.vue"),
      [RouterViews.MAIN_FOOTER]: () => import("@COMP/MainFooter.vue"),
    },
  },
  {
    path: `${Routes.BLOG.path}`,
    name: `${Routes.BLOG.name}`,
    components: {
      [RouterViews.MAIN_HEADER]: () => import("@COMP/MainHeader.vue"),
      [RouterViews.MAIN_CONTENT]: () => import("@VIEWS/BlogPage.vue"),
      [RouterViews.MAIN_FOOTER]: () => import("@COMP/MainFooter.vue"),
    },
  },
  {
    path: `${Routes.BLOG_CONTENT.path}/:id`,
    name: `${Routes.BLOG_CONTENT.name}`,
    components: {
      [RouterViews.MAIN_HEADER]: () => import("@COMP/MainHeader.vue"),
      [RouterViews.MAIN_CONTENT]: () => import("@VIEWS/ViewBlogPage.vue"),
      [RouterViews.MAIN_FOOTER]: () => import("@COMP/MainFooter.vue"),
    },
    meta: {
      breadcrumbs: [Routes.HOME, Routes.BLOG, Routes.BLOG_CONTENT],
    },
  },
  {
    path: `${Routes.GALLERY.path}`,
    name: `${Routes.GALLERY.name}-post`,
    redirect: `${Routes.PHOTOS.path}`,
    components: {
      [RouterViews.MAIN_HEADER]: () => import("@COMP/MainHeader.vue"),
      [RouterViews.MAIN_CONTENT]: () => import("@VIEWS/GalleryPage.vue"),
      [RouterViews.MAIN_FOOTER]: () => import("@COMP/MainFooter.vue"),
    },
    children: [
      {
        path: `${Routes.PHOTOS.path}`,
        name: `${Routes.PHOTOS.name}`,
        component: () => import("@VIEWS/PhotosPage.vue"),
      },
      {
        path: `${Routes.VIDEOS.path}`,
        name: `${Routes.VIDEOS.name}`,
        component: () => import("@VIEWS/VideosPage.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // Scroll to the top of the page before entering any route

  if (
    from.name === Routes.FAMILY_LIFE_EDUCATION_ITEM.name &&
    to.name === Routes.FAMILY_LIFE_EDUCATION_ITEM.name
  ) {
    next();
  } else {
    window.scrollTo(0, 0);
    next();
  }
});

export default router;
